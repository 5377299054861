import * as React from "react";
import ManualLayout from "../../components/pages/manual/ManualLayout";
import ManualBody from "../../components/pages/manual/ManualBody";
import Seo from "../../components/seo";

const Publish = () => {
  return (<>
    <ManualLayout selected='publish'>
      <ManualBody
        title='发布任务'
      />
    </ManualLayout>
  </>)
}

export default Publish;

export const Head = () => <Seo />
